import { extractFragmentChildren } from '@common/libs/helpers/app/ReactHelpers';
import {
  ButtonGroup as MuiButtonGroup,
  styled,
  type ButtonGroupTypeMap as MuiButtonGroupTypeMap
} from '@mui/material';
import { type OverrideProps as MuiOverrideProps } from '@mui/material/OverridableComponent';
import React, {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type PropsToOmit = 'color'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'variant';

type AxButtonGroupCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxButtonGroupTypeMap<P = object, D extends React.ElementType = 'div'> = MuiButtonGroupTypeMap<P & AxButtonGroupCustomProps, D>;

export type AxButtonGroupProps<
  D extends ElementType = AxButtonGroupTypeMap['defaultComponent'],
  P = object,
> = Omit<MuiOverrideProps<AxButtonGroupTypeMap<P & AxButtonGroupCustomProps, D>, D>, PropsToOmit>;

const styledOptions = {
  name: 'AxButtonGroup'
};

const StyledAxButtonGroup = styled(MuiButtonGroup, styledOptions)<AxButtonGroupProps>(() => {

  return {
    '.MuiButtonGroup-grouped:not(:last-of-type):hover': {
      // This ensure that BorderGroup negative margin doen't hide the right border on hover
      zIndex: 999
    }
  };
});

/**
 * The AxButtonGroup component can be used to group related buttons.
 *
 * ## Links
 * * • [MUI | ButtonGroup Demo](https://mui.com/material-ui/react-button-group/)
 * * • [MUI | ButtonGroup API](https://mui.com/material-ui/api/button-group/)
 * * • [Mockup](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=13049-5725&m=dev)
 */
export const AxButtonGroup = forwardRef(({
  children,
  ...otherProps
}: AxButtonGroupProps, ref: Ref<HTMLDivElement>) => {
  const buttonProps: AxButtonGroupProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxButtonGroup { ...buttonProps } ref={ ref }>
      { /* We need to override the default large size in AxButton. Otherwise, ButtonGroup size doesn't take effect */ }
      { extractFragmentChildren(children).map((child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            size: otherProps.size,
            disabled: otherProps.disabled
          });
        }
        return child;
      }) }
    </StyledAxButtonGroup>
  );
});

export default AxButtonGroup;
