import {
  Chip as MuiChip,
  styled,
  type ChipProps as MuiChipProps,
  type ChipTypeMap as MuiChipTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import { type CSSObject } from '@mui/system';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    article_draft: true
    article_expired: true
    article_in_review: true
    article_published: true
    article_reported: true
    assesment_evaluation: true
    assesment_exam: true
    autocomplete: true,
    beta: true
    content_article: true
    content_event: true
    content_link: true
    content_question: true
    content_task: true
    default: true
    milestone: true
    training_assigned: true
    training_certification: true
    training_enrolled: true
    training_module: true
    training_path: true
    training_reassigned: true
    training_recommendation: true
    training_survey: true
    training_topic: true
    validation_failed: true
    validation_passed: true
    validation_pending: true
  }
}

export type CHIP_VARIANTS = MuiChipProps['variant'];

type AxTagCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

type propsToOmit = 'size' | 'clickable';

export type AxTagTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & Omit<MuiChipTypeMap['props'], propsToOmit> & AxTagCustomProps
  defaultComponent: D;
};

export type AxTagProps<
  D extends ElementType = AxTagTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxTagTypeMap<P & AxTagCustomProps, D>, D>;

const StyledAxTag = styled(MuiChip)<AxTagProps>((props) => {
  const {
    variant,
    onDelete,
    theme
  } = props;

  // default with no variant is UIKit Base tag
  const defaultStyles: CSSObject = {
    borderRadius: theme.uiKit.spacingM,
    fontWeight: theme.uiKit.fontWeightMedium,
    fontSize: theme.uiKit.fontSizeXS,
    textTransform: 'uppercase',
    height: theme.uiKit.spacingL,
    margin: theme.uiKit.spacingXS,
    padding: `${ theme.uiKit.spacingXS } ${ theme.uiKit.spacingS }`,
    border: `0.1rem solid ${ theme.uiKit.colorGrey40 }`,
    ...theme.mixins.useGapPolyfillStyles('row', theme.uiKit.spacingXS) as CSSObject
  };

  if (variant === 'autocomplete') {
    const commontStyles: CSSObject = {
      ...defaultStyles,
      textTransform: 'unset',
      opacity: 1,
      padding: 0,

      // Override MUI styles for Autocomplete Chip
      border: `0.1rem solid ${ theme.uiKit.colorGrey40 } !important`,
      borderRadius: `${ theme.uiKit.spacingM } !important`,

      '&.Mui-disabled': {
        opacity: 1,
        backgroundColor: `${ theme.uiKit.colorGrey20 } !important`
      },

      '& .MuiChip-deleteIcon': {
        marginRight: 0,
        ...(onDelete == null && {
          display: 'none'
        })
      }
    };

    return {
      ...commontStyles,
      backgroundColor: 'transparent',
      borderColor: theme.uiKit.colorGrey30
    };
  }
  if (variant === 'training_module') {
    return {
      ...defaultStyles,
      color: theme.uiKit.tagModuleTextColor,
      backgroundColor: theme.uiKit.tagModuleBackgroundColor,
      borderColor: theme.uiKit.tagModuleBorderColor
    };
  }
  if (variant === 'training_topic') {
    return {
      ...defaultStyles,
      color: theme.uiKit.tagTopicTextColor,
      backgroundColor: theme.uiKit.tagTopicBackgroundColor,
      borderColor: theme.uiKit.tagTopicBackgroundColor
    };
  }
  if (variant === 'training_path') {
    return {
      ...defaultStyles,
      color: theme.uiKit.tagPathTextColor,
      backgroundColor: theme.uiKit.tagPathBackgroundColor,
      borderColor: theme.uiKit.tagPathBackgroundColor
    };
  }
  if (variant === 'training_survey') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagSurveyBackgroundColor,
      color: theme.uiKit.tagSurveyTextColor,
      borderColor: theme.uiKit.tagSurveyBorderColor
    };
  }
  if (variant === 'training_certification') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagCertificationBackgroundColor,
      color: theme.uiKit.tagCertificationTextColor,
      borderColor: theme.uiKit.tagCertificationBackgroundColor
    };
  }
  if (variant === 'assesment_exam') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagExamBackgroundColor,
      color: theme.uiKit.tagExamTextColor,
      borderColor: theme.uiKit.tagExamBackgroundColor
    };
  }
  if (variant === 'content_task') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagTaskBackgroundColor,
      color: theme.uiKit.tagTaskTextColor,
      borderColor: theme.uiKit.tagTaskBackgroundColor
    };
  }
  if (variant === 'content_event') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagEventBackgroundColor,
      color: theme.uiKit.tagEventTextColor,
      borderColor: theme.uiKit.tagEventBackgroundColor
    };
  }
  if (variant === 'assesment_evaluation') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagEvaluationBackgroundColor,
      color: theme.uiKit.tagEvaluationTextColor,
      borderColor: theme.uiKit.tagEvaluationBackgroundColor
    };
  }
  if (variant === 'beta') {
    return {
      ...defaultStyles,
      border: `1px solid ${ theme.uiKit.tagBetaTextColor }`,
      backgroundColor: theme.uiKit.tagBetaBackgroundColor,
      color: theme.uiKit.tagBetaTextColor,
      borderColor: theme.uiKit.tagBetaBackgroundColor
    };
  }
  if (variant === 'training_recommendation') {
    return {
      ...defaultStyles,
      border: `1px solid ${ theme.uiKit.tagRecommendationTextColor }`,
      backgroundColor: theme.uiKit.tagRecommendationBackgroundColor,
      color: theme.uiKit.tagRecommendationTextColor,
      borderColor: theme.uiKit.tagRecommendationBackgroundColor
    };
  }
  if (variant === 'milestone' || variant === 'training_assigned' || variant === 'training_enrolled') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagMilestoneBackgroundColor,
      color: theme.uiKit.tagMilestoneTextColor,
      borderColor: theme.uiKit.tagMilestoneBackgroundColor
    };
  }
  if (variant === 'validation_passed') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagValidationPassedBackgroundColor,
      color: theme.uiKit.tagValidationPassedTextColor,
      borderColor: theme.uiKit.tagValidationPassedBackgroundColor
    };
  }
  if (variant === 'validation_failed') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagValidationFailedBackgroundColor,
      color: theme.uiKit.tagValidationFailedTextColor,
      borderColor: theme.uiKit.tagValidationFailedBackgroundColor
    };
  }
  if (variant === 'validation_pending') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagValidationPendingBackgroundColor,
      color: theme.uiKit.tagValidationPendingTextColor,
      borderColor: theme.uiKit.tagValidationPendingBackgroundColor
    };
  }
  if (variant === 'content_article') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagArticleBackgroundColor,
      color: theme.uiKit.tagArticleTextColor,
      borderColor: theme.uiKit.tagArticleBackgroundColor
    };
  }
  if (variant === 'content_question') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagQuestionBackgroundColor,
      color: theme.uiKit.tagQuestionTextColor,
      borderColor: theme.uiKit.tagQuestionBackgroundColor
    };
  }
  if (variant === 'content_link') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagLinkBackgroundColor,
      color: theme.uiKit.tagLinkTextColor,
      borderColor: theme.uiKit.tagLinkBackgroundColor
    };
  }
  if (variant === 'article_published') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagPublishedBackgroundColor,
      color: theme.uiKit.tagPublishedTextColor,
      borderColor: theme.uiKit.tagPublishedBackgroundColor
    };
  }
  if (variant === 'article_in_review') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagInReviewBackgroundColor,
      color: theme.uiKit.tagInReviewTextColor,
      borderColor: theme.uiKit.tagInReviewBackgroundColor
    };
  }
  if (variant === 'article_reported') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagReportedBackgroundColor,
      color: theme.uiKit.tagReportedTextColor,
      borderColor: theme.uiKit.tagReportedBackgroundColor
    };
  }
  if (variant === 'training_reassigned') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagReassignedBackgroundColor,
      color: theme.uiKit.tagReassignedTextColor,
      borderColor: theme.uiKit.tagReassignedBackgroundColor
    };
  }
  if (variant === 'article_expired') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagExpiredBackgroundColor,
      color: theme.uiKit.tagExpiredTextColor,
      borderColor: theme.uiKit.tagExpiredBackgroundColor
    };
  }
  if (variant === 'article_draft') {
    return {
      ...defaultStyles,
      backgroundColor: theme.uiKit.tagDraftBackgroundColor,
      color: theme.uiKit.tagDraftTextColor,
      borderColor: theme.uiKit.tagDraftBackgroundColor
    };
  }

  return defaultStyles;
});

export const AxTag: MuiOverridableComponent<AxTagTypeMap> = forwardRef(
  ({
    children,
    variant = 'default',
    ...otherProps
  }: AxTagProps, ref: Ref<HTMLDivElement>) => {
    const propsWithDefaults: AxTagProps = {
      children,
      variant,
      ...otherProps
    };

    return (
      <StyledAxTag
        tabIndex={ -1 }
        { ...propsWithDefaults }
        ref={ ref }
      >
        { children }
      </StyledAxTag>
    );
  }
);

export default AxTag;
