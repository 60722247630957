const Backbone = require('Backbone');
const Cocktail = require('backbone.cocktail');
const _ = require('underscore');

const GroupCriteria = require('@admin/apps/main/models/GroupCriteria');
const GroupType = require('@common/data/enums/GroupType');
const ProtectedEntityMixin = require('@common/data/mixins/ProtectedEntityMixin');
const BusinessUnitOwnedEntity = require('@common/data/mixins/BusinessUnitOwnedEntity');
const FilterableAttributesEnum = require('@common/data/enums/FilterableAttributesEnum');
const GroupDateConditionHelpers = require('@common/libs/helpers/features/GroupDateConditionHelpers');

class Group extends Backbone.Model {
  get className() {
    return 'Group';
  }

  validators() {
    return {
      name: 'required'
    };
  }

  defaults() {
    return {
      type: 0,
      targetType: 'group',
      isEditable: true,
      name: '',
      reportFilterListVisibilityType: 'adminOnly',
      prizeCount: 0,
      isPrivate: true,
      criteria: new GroupCriteria().toJSON(),
      access: ['EDIT', 'VIEW'],
      businessUnitId: null
    };
  }

  apiEndpoint() {
    return GroupType.getGroupEndpointByType(this.get('type'));
  }

  parse(res ) {
    const attrs = (res && res.entity) || res;

    attrs['criteria'] = {};

    const keys = Group.getFilterableAttributes();
    for (const key of keys) {
      attrs['criteria'][key] = attrs[key];
    }

    return attrs;
  }

  toGrid() {
    return {
      name: this.get('name')
    };
  }

  toOption() {
    return {
      id: this.id,
      value: this.get('name')
    };
  }

  toOptionCheckbox(model = this) {
    return {
      id: model.id,
      value: model.id,
      label: model.get('name'),
      name: `checkbox-group-<%- id %>`
    };
  }

  toJSON(...args) {
    const json = super.toJSON(...args);
    const keys = Group.getFilterableAttributes();
    const criteria = json['criteria'];
    for (const key of Array.from(keys)) {
      json[key] = criteria[key];
    }
    return json;
  }

  save(attributes, options) {
    const body = _.extend(this.toJSON(), attributes || {});
    delete body.criteria;
    if (!GroupDateConditionHelpers.isDateConditionComplete(body.dateCondition)) {
      body.dateCondition = null;
    }

    const newOptions = {
      ...options,
      attrs: body
    };

    return super.save(attributes, newOptions);
  }
}

Group.getFilterableAttributes = FilterableAttributesEnum.values;

Group.isGroupAreasOfInterest = (attribute) => {
  return attribute === FilterableAttributesEnum.GroupAreasOfInterest;
};

Group.isGroupTeamAttributes = (attribute) => {
  return attribute === FilterableAttributesEnum.GroupTeamAttributes;
};

Cocktail.mixin(Group, ProtectedEntityMixin, BusinessUnitOwnedEntity);

module.exports = Group;
