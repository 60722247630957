// Module
var code = `<div class="ax-grid">
  <div class="js-search-bar-wrapper">
    <div class="search-input-view ax-grid ax-grid--no-gutter ax-grid--align-items-center">
      <%= axSearch({ fullWidth: true, iconClassRight: 'qa-input-search-clear icon-remove js-remove ' + clearSearchClass,
        placeholder: placeholderText, size: 'l' , value: searchQuery }) %>
    </div>
  </div>
  <div class="communities-search__add">
    <div class="ax-grid ax-grid--no-gutter ax-grid--justify-content-end">
      <% if (canAddCommunity) { %>
        <a href="<%= communityManagementPath %>"
          class="communities-search__add--button ax-button ax-button--branded ax-grid__col--auto-size">
          <span class="icon-plus"></span>
          <span><%- t('communitiesManagement.communities.add') %></span>
        </a>
        <% } %>
          <% if (shouldShowEllipsisMenu) { %>
            <div class="js-communities-search__menu communities-search__menu ax-grid__col--auto-size"></div>
            <% } %>
    </div>
  </div>
</div>
<div class="ax-grid">
  <div class="ax-grid communities-search-filters">
    <%= axCheckbox({ classNameInput: 'js-archived-filter' , className:'ax-grid__col--m-auto-size', label:
      t('communitiesManagement.communities.searchFilterArchived') }) %>
      <% if (isCommsEnabled) { %>
        <%= axCheckbox({ classNameInput: 'js-team-communities-filter' , className:'ax-grid__col--l-auto-size', label:
          t('communitiesManagement.communities.searchFilterTeamBased') }) %>
          <% } %>
  </div>
  <div class="communities-count-text ax-grid__col--auto-size"></div>
</div>
`;
// Exports
module.exports = code;