const Marionette = require('Marionette');
const I18n = require('@common/libs/I18n');
const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const FilterableCommunityController = require('@common/components/filterableCommunity/FilterableCommunityController');
const CommunitySelectorView = require('@common/components/discover/edit/CommunitySelectorView');

const CommunityAction = require('@common/data/enums/CommunityAction');

require('@common/components/forms/editors/switch/Form.Editor.Switch');

class CommunityMetadataSettingsController extends LayoutController {
  initialize(options = {}) {
    ({
      model: this.model,
      community: this.community,
      isEditing: this.isEditing,
      currentUser: this.currentUser,
      authChannel: this.authChannel,
      glChannel: this.glChannel,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList,
      currentBusinessUnitId: this.currentBusinessUnitId
    } = options);

    this.viewDefinition = this.viewDefinition.bind(this);
  }

  viewDefinition() {
    return {
      ViewClass: CommunitySelectorView,
      model: this.model,
      currentUser: this.currentUser,
      authChannel: this.authChannel,
      glChannel: this.glChannel,
      pageSharedCommunitiesList: this.pageSharedCommunitiesList,
      regions: {
        communitySelectorRegion: '.js-community-selector-region'
      }
    };
  }

  regionControllers() {
    return {
      communitySelectorRegion: this._canChangeCommunity()
        ? this._getCommunitySelectorDefinition()
        : this._getCommunityNameDefinition()
    };
  }

  _setCommunity(community) {
    const previousCommunityId = this.model.get('community');
    const isMovingCommunity = !this.model.isNew()
                              && previousCommunityId !== null
                              && previousCommunityId !== community;

    this.model.set('community', community);

    if (isMovingCommunity && this.pageSharedCommunitiesList) {
      this.pageSharedCommunitiesList.reset();

      if (this.model.get('communities')) {
        const primaryCommunity = this.model.get('communities').find((c) => {
          return c.isHomeCommunity
        });
        if (primaryCommunity) {
          this.model.set('communities', [primaryCommunity]);
        }
      }
    }
  }

  _canChangeCommunity() {
    return this.model.isNew() || this.community.canMove();
  }

  _getCommunitySelectorDefinition() {
    const pageType = this.model.getType();
    const communityAction = this.isEditing ? CommunityAction.PUBLISH_ACCESS : CommunityAction.AUTHOR_PAGETYPE;

    return {
      ViewControllerClass: FilterableCommunityController,
      label: I18n.t('discover.metadata.community'),
      contextModel: this.model,
      community: this.community,
      pageType,
      communityAction,
      setCommunityHandler: this._setCommunity.bind(this),
      filterCommunities: (communities) => {
        if (this.isEditing && this.currentBusinessUnitId != null) {
          return communities.filter((community) => {
            return community.getBusinessUnitId() === this.currentBusinessUnitId;
          });
        }
        return communities;
      },
      delegateEvents: {
        'change:community': (community) => {
          this.trigger('change:community', community);
        }
      }
    };
  }

  _getCommunityNameDefinition() {
    return {
      viewDefinition: {
        ViewClass: Marionette.ItemView,
        template: `
          <div class="metadata__input">
            <label><%- t('discover.metadata.community') %></label>
            <p>${ this.community.getName() }</p>
          </div>\
        `
      }
    };
  }
}

module.exports = CommunityMetadataSettingsController;
