const _ = require('underscore');
const SelectedNodeTreeSerializer = require('@common/components/axonFancyTree/SelectedNodeTreeSerializer');

// This serializer serves as a bridge by adapting the internal state of the tree editor and proxying it up
// to the FilterableSelector to maintain the state it wants
class HierarchySelectorToEditorAdapterSerializer extends SelectedNodeTreeSerializer {

  constructor(...args) {
    super(...args);
    const options = args[0] || {};
    this._collection = options.collection;
    this._shouldRollupChildren = options.shouldRollupChildren;
  }

  deserialize(selectedItems = []) {
    const selectedIds = _.map(selectedItems, (item) => {
      return item.id;
    });
    return super.deserialize(selectedIds);
  }

  serialize(treeEditor) {
    const selectedNodes = treeEditor.getSelectedNodes(this._shouldRollupChildren);

    return _.reduce(selectedNodes, (selected, node) => {
      if (!node.unselectable) {
        // Try to get the model directly from the collection
        let validModel = this._collection.get(node.data.model);

        // If not found directly, look for it in children of collection models
        if (!validModel && node.data.model?.id) {
          // Find the model in any child collection
          this._collection.some((model) => {
            const children = model.get('children');
            if (children) {
              // Find matching child by ID and return true if found
              return children.some((child) => {
                if (child.id === node.data.model.id) {
                  validModel = child;
                  return true;
                }
                return false;
              });
            }
            return false;
          });
        }

        if (validModel) {
          selected.push(validModel);
        }
      }
      return selected;
    }, []);
  }
}

module.exports = HierarchySelectorToEditorAdapterSerializer;
