const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');
const BusinessUnit = require('@common/data/models/businessUnit/BusinessUnit');

class BusinessUnitList extends Backbone.Collection {
  apiEndpoint() {
    return 'businessUnits';
  }

  initialize(models = [], options = {}) {
    this.model = BusinessUnit;
    super.initialize(models, options);
  }

  static getBusinessUnitsForUser(userId) {
    const future$ = $.ajax({apiEndpoint: `businessUnits/all/${ userId }`});

    return future$.then((response) => {
      return new BusinessUnitList(response, {parse: true});
    });
  }

  getNameFor(businessUnitId) {
    // If you have access to no BUs, then it makes sense for this to just be empty
    if (this.length === 0) {
      return '';
    }

    const businessUnit = this.get(businessUnitId);
    return businessUnit ? businessUnit.getName() : I18n.t('settings.businessUnits.global');
  }

  getGlobalBusinessUnitIfAvailable() {
    const lastItem = this.last();

    if (lastItem && lastItem.id === null) {
      return lastItem;
    }

    return undefined;
  }
}

module.exports = BusinessUnitList;
