// Module
var code = `<h1 class="page-header">
	<div class="ax-container">
		<div class="page-header__content">
			<span class="page-header__icon icon-communities" aria-hidden="true"></span>
			<span class="page-header__title"><%- t('communitiesManagement.title') %></span>
		</div>
	</div>
</h1>

<div class="ax-container">
	<div class="page-content">
		<div class="js-communities-management-tabs clearfix"></div>
	</div>
</div>
`;
// Exports
module.exports = code;