const { LayoutView } = require('Marionette');
const { triggerResize } = require('@common/libs/helpers/app/BrowserHelpers');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const LanguageList = require('@common/data/collections/LanguageList');
const AxonSelect = require('@common/components/forms/editors/axonSelect/Form.Editor.AxonSelect');
const FolderType = require('@training/apps/training/enums/FolderType');
const SelectOptionCollection = require('@common/libs/selectOptionCollection/SelectOptionCollection');
const DescriptionRteView = require('@training/apps/search/communitiesManagement/generalSettings/DescriptionRteView');

require('@common/components/forms/editors/translatableName/Form.Editor.TranslatableName');
require('@common/components/forms/editors/imageCrop/Form.Editor.ImageCrop');

class GeneralSettingsTabView extends LayoutView {
  initialize(options = {}) {
    ({model: this.model} = options);
    this.setOriginalCodeAndDescription = this.setOriginalCodeAndDescription.bind(this);
    this.setLocalizableDefaults = this.setLocalizableDefaults.bind(this);
  }

  get id() {
    return 'general-settings-tab';
  }

  get template() {
    return require('@training/apps/search/communitiesManagement/generalSettings/GeneralSettingsTabView.html');
  }

  ui() {
    return {
      form: '.js-form',
      note: '.js-note',
      languageSelector: '.js-language-selector'
    }
  }

  regions() {
    return {
      rteDescription: '.js-description'
    };
  }

  onAttach() {
    this.form = new Form({
      el: this.ui.form,
      model: this.model,
      context: {
        folderTypes: new SelectOptionCollection(FolderType.values().filter((folderType) => {
          return folderType !== FolderType.ALL;
        })
          .map((folderType) => {
            return {
              value: I18n.t(`communitiesManagement.communityType.${ folderType }`),
              id: folderType
            };
          })),
        nameOptions: {
          index: 0,
          translatedLanguage: this.currentLanguage,
          isLocalizableString: true,
          maxLength: 60
        },
        imageCropOptions: {
          flashErrorFn: (e) => {
            window.app.layout.flash.error(e);
          }
        },
        adminHideOptions: {
          ariaLabel: I18n.t('communitiesManagement.generalSettings.switchLabel')
        }
      }
    });

    this._renderLanguageSelector();

    this.listenTo(this.languageSelector, 'change', this._onLanguageChange);
    this.listenTo(this.model, 'sync', this.setOriginalCodeAndDescription);
    this.listenTo(this.model, 'sync change update', () => {
      this._updateForm();
    });

    this._updateData();
    this._renderRteDescription();

    this._updateForm();
    this.setOriginalCodeAndDescription();
  }

  _renderRteDescription() {
    this.rteDescriptionView = new DescriptionRteView({
      options: {
        model: this.model,
        isLocalizableString: true,
        translatedLanguage: this.currentLanguage
      }
    });

    // assign the editor to a form field as form takes care of setting/getting the value
    this.form.fields.descriptions = this.form.fields.descriptions || {};
    this.form.fields.descriptions.editor = this.rteDescriptionView;

    this.rteDescription.show(this.rteDescriptionView);
  }

  _renderLanguageSelector() {
    const languages = TenantPropertyProvider.get().getProperty('languages');
    this.languageSelector = new AxonSelect({
      el: this.ui.languageSelector,
      options: {
        collection: LanguageList.fromLanguageCodeList(languages),
        axonSelectOptions: {
          useChosen: false
        },
        showValuesObject: false,
        optionsClass: ''
      }
    });

    this.languageSelector.render();
  }

  setOriginalCodeAndDescription() {
    const communityCode = this.model.get('code');
    if (communityCode) {
      // Soft cache the old code - the server will ignore it
      this.model.set('originalCode', communityCode);
    }

    this.rteDescriptionView.updateSelectedLanguage(this.currentLanguage);
  }

  setLocalizableDefaults() {
    this.model.get('names').setDefaultWithPreferredLocale(this.defaultLanguage);
    this.model.get('descriptions').setDefaultWithPreferredLocale(this.defaultLanguage);
  }

  _updateForm() {
    this.form.update(this.model);
    this._toggleSwitchText(this.model.get('hiddenFromAdmin'));
    triggerResize(true);
  }

  _toggleSwitchText(isHidden = false) {
    const string = isHidden
      ? I18n.t('communitiesManagement.generalSettings.switch.hide')
      : I18n.t('communitiesManagement.generalSettings.switch.show');
    this.ui.note.text(string);
  }

  _updateData() {
    let userDefaultLanguage = window.apps.auth.session.user.get('language') || 'EN';

    // Handle situation where admin's language may not be set
    if (userDefaultLanguage === 'XX') {
      userDefaultLanguage = TenantPropertyProvider.get().getProperty('defaultLanguage');
    }

    this.defaultLanguage = userDefaultLanguage;
    this.currentLanguage = userDefaultLanguage;

    this.languageSelector.setValue(userDefaultLanguage);

    this.updateFormWithCurrentLanguage();
  }

  _onLanguageChange() {
    // save existing text manually before switching languages
    // as froala button clicks are not detected by onEditorTextChange
    this.rteDescriptionView.onEditorTextChange();

    const newLanguage = this.languageSelector.getValue();
    this.currentLanguage = newLanguage;
    this.updateFormWithCurrentLanguage();
  }

  updateFormWithCurrentLanguage() {
    this.form.fields.names.editor.updateSelectedLanguage(this.currentLanguage);

    if (this.rteDescriptionView) {
      this.rteDescriptionView.updateSelectedLanguage(this.currentLanguage);
    }
  }
}

module.exports = GeneralSettingsTabView;
