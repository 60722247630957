const UIKit = require('@training/widgets/UIKit');
const Backbone = require('Backbone');
const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const logging = require('logging');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');

const LayoutController = require('@common/libs/UI/controllers/LayoutController');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const PageEditViewLayout = require('@training/apps/articles/PageEditViewLayout');


const RteView = require('@common/components/discover/edit/RteView');
const TrainingModuleContentView = require('@common/components/discover/views/TrainingModuleContentView');
const LanguageList = require('@common/data/collections/LanguageList');
const TagList = require('@common/components/discover/collections/TagList');
const TitleEditView = require('@common/components/discover/edit/TitleEditView');

const TrainingModuleType = require('@common/data/enums/TrainingModuleType');

const ArticleMetadataEditController = require('@training/apps/articles/ArticleMetaDataEditController');
const ArticleEditButtonBarController = require('@training/apps/articles/ArticleEditButtonBarController');
const LockController = require('@training/apps/articles/LockController');
const ArticlePublishAllModal = require('@training/apps/articles/modals/ArticlePublishAllModal');
const ChangeLanguageModal = require('@training/apps/articles/modals/ChangeLanguageModal');

const FileFactory = require('@common/libs/file/FileFactory');
const FileFactoryMedia = require('@common/data/models/media/FileFactory');
const MediaFactory = require('@common/libs/file/MediaFactory');

const VideoCaptioningService = require('@common/services/media/VideoCaptioningService');
const TranscriptionMethodSupplierTenantStrategy = require('@common/services/media/TranscriptionMethodSupplierTenantStrategy');
const MediaHelpers = require('@common/libs/file/MediaHelpers');

const AxonifyExceptionFactory = require('AxonifyExceptionFactory');
const AxonifyExceptionCode = require('AxonifyExceptionCode');

const glChannel = Backbone.Wreqr.radio.channel('global');

const authChannel = Backbone.Wreqr.radio.channel('authChannel');
class ArticleEditController extends LayoutController {

  initialize(options = {}) {
    ({
      model: this.model,
      languageData: this.languageData,
      canEditContent: this.canEditContent,
      canEditTitle: this.canEditTitle,
      canEditAttachments: this.canEditAttachments
    } = options);

    this.originalModel = this.model;

    this.model = this.model.clone({deepClone: true});

    //if there is already at least one saved language variant, we can treat this as an alternate language variant
    this.alternateLanguage = _.some(this.languageData.keys(), (key) => {
      return key !== 'bundleId' && this.languageData.get(key) !== null;
    });

    this.fileFactoryMedia = new FileFactoryMedia();

    const transcriptionMethodSupplier = TranscriptionMethodSupplierTenantStrategy.fromTenant();

    this.captioningService = new VideoCaptioningService(
      transcriptionMethodSupplier,
      MediaHelpers
    );

    this.listenTo(this.metaDataController, 'change:attachments', (attachmentsList) => {
      this.rteView.trigger('change:attachments', attachmentsList);
    });

    this.lockController = new LockController({model: this.originalModel});
    this.listenTo(this.lockController, 'lock:error', this.onLockError);

    window.app.layout.toggleFooter(false);

    if (!this.model.isNew()) {
      this.lockController.lockPage().done(() => {
        const viewDestroyed = this.getView() && this.getView().isDestroyed;
        return !viewDestroyed;
      });
    }
  }

  viewDefinition() {
    return {
      ViewClass: PageEditViewLayout
    };
  }

  regionControllers() {
    const sessionUser = window.apps.auth.session.user;
    const supportedLanguages = LanguageList.fromLanguageCodeList(TenantPropertyProvider.get().getProperty('languages'));
    const tagList = new TagList([]);

    return {
      titleRegion: {
        viewDefinition: {
          ViewClass: TitleEditView,
          model: this.model,
          canEditTitle: this.canEditTitle,
          alternateLanguage: this.alternateLanguage
        }
      },
      contentRegion: this._getContentRegionDefinition(supportedLanguages, sessionUser),
      metadataRegion: {
        ViewControllerClass: ArticleMetadataEditController,
        model: this.model,
        authChannel,
        glChannel,
        tagList,
        languageData: this.languageData,
        currentBusinessUnitId: this.model.isNew()
          ? null
          : this.model.get('communities')
            ?.find((c) => {
              return c.isHomeCommunity;
            })
            ?.community
            ?.id,
        isEditing: !this.model.isNew(),
        languageList: supportedLanguages,
        currentUser: window.apps.auth.session.user,
        isMediaAttachmentsEnabled: this.canEditAttachments,
        fileFactory: (fileType, options) => {
          return this._createMediaFromFile(fileType, options);
        },
        confirmActionOnModified: this._confirmActionOnLanguageChange.bind(this),
        delegateEvents: {
          'change:attachments': (documentList) => {
            this.model.set('isDirty', true);
            this.findControllerByRegion('contentRegion').getView()
              .trigger('change:attachments', documentList);
          },
          'view:change:community': (metadataController, view, community) => {
            const buttonBarController = this.findControllerByRegion('actionBarRegion');
            buttonBarController.renderButtonsForCommunity(community, this.model);
          }
        }
      },
      actionBarRegion: {
        ViewControllerClass: ArticleEditButtonBarController,
        model: this.model,
        languageData: this.languageData,
        originatingCommunity: this.model.get('community'),
        saveCallback: this._savePage.bind(this),
        publishAllCallback: this._publishAll.bind(this)
      }
    };
  }

  _confirmActionOnLanguageChange(callback) {
    if (!this.model.hasChanged() && !this.model.get('isDirty') && !this.model.currentVersion.richContent.get('isDirty')) {
      callback();
      return;
    }

    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal change-language-modal confirm-dialog-view modal--s'
    });

    const modalChildView = new ChangeLanguageModal({
      model: this.model,
      submitChanges: this._savePage.bind(this),
      callback
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _getContentRegionDefinition(supportedLanguages, sessionUser) {
    if (this.canEditContent) {
      let warning = null;
      if (this.model.get('isExternallyManaged')) {
        warning = this.model.get('externallyManagedByName');
      }
      return {
        viewDefinition: {
          ViewClass: RteView,
          model: this.model.currentVersion.richContent,
          warningManagedBy: warning,
          isMediaAttachmentsEnabled: this.canEditAttachments,
          fileFactory: (fileType, options) => {
            return this._createMediaFromFile(fileType, options);
          },
          supportedLanguages,
          sessionUser
        },
        delegateEvents: {
          'view:image:clicked': this._showMedia
        }
      };
    }

    return {
      ViewControllerClass: LayoutController,
      viewDefinition: {
        ViewClass: TrainingModuleContentView,
        model: this.model,
        sessionUser,
        isEditing: true
      },
      delegateEvents: {
        'view:image:clicked': this._showMedia
      }
    };
  }

  _createMediaFromFile(fileType, fileData, fileOptions = {}) {
    const $input = fileData.file;
    const options = _.extend({}, fileOptions, { fileType });
    return this.fileFactoryMedia.createFileFromInput($input, options, fileData);
  }

  _showMedia(controller, view, mediaId) {
    const media = view.model.currentVersion.richContent._getMediaById(mediaId);
    const mediaModel = MediaFactory.createMediaFromJSON(FileFactory, media);
    window.app.layout.showMedia(mediaModel);
  }

  _savePage(onSuccess = this._saveModel.bind(this), onError = this._showErrors.bind(this)) {
    const currentRequest = this._currentRequest != null ? this._currentRequest.state() : undefined;
    if (currentRequest === 'pending') {
      return false;
    }

    let errors = [];

    if (this.canEditContent) {
      errors = errors.concat(this.findControllerByRegion('contentRegion').getView()
        .commit());
    }

    if (this.model.hasChanged()) {
      // This is a workaround for an issue caused by the model having multiple forms linked to it
      // Form.commit clears model.hasChanged() if there are no validation errors, but there could be errors elsewhere
      // that prevent the model from being saved. We need to preserve hasChanged until all forms are error-free.
      this.model.set('isDirty', true);
    }

    const titleView = this.findControllerByRegion('titleRegion').getView();
    if (this.canEditTitle) {
      errors = errors.concat(titleView.commit());
      if (_.compact(errors).length !== 0) {
        onError(errors);
        return false;
      }
    }
    // make metadata errors special so that the
    // metadata will open
    errors = errors.concat(this.findControllerByRegion('metadataRegion').commit());
    if (_.compact(errors).length !== 0) {
      this.getView().openMetadata();
      onError(errors);
      return false;
    }

    this.model.unset('isDirty');
    this.model.currentVersion.unset('id');
    this.model.currentVersion.richContent.unset('id');
    this.model.currentVersion.richContent.unset('isDirty');

    if (this.canEditContent) {
      this._currentRequest = this._processMediaElementsForVideo();
      return this._currentRequest.done(() => {
        this._currentRequest = onSuccess();
        return this._currentRequest;
      }).fail( (xhr) => {
        xhr.skipGlobalHandler = true;
        logging.warn('A closed caption call has failed. This should not happen -- check logic.');
        this._currentRequest = onSuccess();
      });
    }

    this._currentRequest = onSuccess();
    return this._currentRequest;
  }

  _publishAll() {
    this.findControllerByRegion('contentRegion').getView()
      .commit();

    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal article-publish-all-modal qa-article-publish-all-modal'
    });

    const modalChildView = new ArticlePublishAllModal({
      model: this.model,
      languageData: this.languageData,
      saveCurrentPage: this._savePage.bind(this),
      title: I18n.t('discover.publishFlow.sendForReviewConfirm')
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    modalView.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  _processMediaElementsForVideo() {
    const richContent = this.model.currentVersion.get('richContent');
    const mediaList = richContent != null ? richContent.media : [];

    const deferreds = [];
    for (const mediaItem of mediaList) {
      if (mediaItem.type === 'video') {
        const videoMedia = this.fileFactoryMedia.createMediaFromJSON(mediaItem);
        const deferred = this._processVideo(videoMedia);
        if (deferred) {
          deferreds.push(deferred);
        }
      }
    }

    // We need every media to resolve before we can do anything about this
    return $.when.apply(...deferreds);
  }

  _processVideo(mediaVideo) {
    const articleLanguage = this.model.get('language');
    const languagesMatch = articleLanguage === mediaVideo.get('language');

    if (languagesMatch) {
      this.captioningService.generateMissingCaptions(mediaVideo, articleLanguage);
    }
  }

  _saveModel() {
    const originalCommunityId = this.originalModel.get('community');
    const newCommunityId = this.model.get('community');
    const communityChanged = originalCommunityId !== newCommunityId;

    return this.model.save()
      .done( () => {
        window.app.layout.flash.success(I18n.t(`discover.pageTypes.${ this.model.get('type') }.save.success`,
          { title: this.model.getTitle() }));
        if (communityChanged) {
          this._handleCommunityChange(newCommunityId);
        }
        this._navigateToViewOnSave();
      })
      .fail( (xhr) => {
        const exception = AxonifyExceptionFactory.fromResponse(xhr);
        const errCode = exception.getErrorCode();

        if (errCode === AxonifyExceptionCode.CONTRACT_ERROR_VALUE_TOO_LONG
          || errCode === AxonifyExceptionCode.INPUT_VALIDATION_FAILURE) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.generic.${ errCode }`));
        }

        // Deleted article, flash error and go back to listing
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`));
          Backbone.history.history.back();
          // Users access rights have changed, flash error and go back to viewing only
        } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED
          || errCode === AxonifyExceptionCode.CONTRACT_ERROR_FEATURE_UNAVAILABLE) {
          this.onPageAccessError(xhr);
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_BUSINESS_UNIT_CONSISTENCY_PROBLEMS) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.error.3088'));
          Backbone.history.history.back();
        }

        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_LANGUAGE_NOT_SUPPORTED) {
          xhr.skipGlobalHandler = true;
          window.app.layout.flash.error(I18n.t('discover.pageTypes.generic.error.3134'));
          Backbone.history.history.back();
        }

        // Missing lock, try to grab it now and reattempt save
        if (errCode === AxonifyExceptionCode.CLIENT_ERROR_OPERATION_REQUIRES_LOCK) {
          xhr.skipGlobalHandler = true;
          this.lockController.lockPage().done(this._saveModel());
        }
      });
  }

  _handleCommunityChange(newCommunityId) {
    this.originalModel.set('community', newCommunityId);

    const communities = this.originalModel.get('communities');
    if (communities) {
      const primaryCommunity = communities.find((c) => {
        return c.isHomeCommunity
      });
      if (primaryCommunity) {
        primaryCommunity.community.id = newCommunityId;
        this.originalModel.set('communities', [primaryCommunity]);
      }
    }

    const richContent = this.originalModel.currentVersion.richContent;

    const richContentAttributes = this.model.currentVersion.richContent.attributes;
    this.originalModel.set(this.model.attributes);

    if (this.originalModel.currentVersion && richContent) {
      this.originalModel.currentVersion.richContent = richContent;
      this.originalModel.currentVersion.richContent.set(richContentAttributes);
    }
  }

  _navigateToViewOnSave() {
    if (this.model.get('trainingModule') && this.model.get('trainingModule').moduleType === TrainingModuleType.SCORM) {
      // scorm modules have a navigation entry because of the iframe, so we need to go back 2.
      Backbone.history.history.go(-2);
      return;
    }

    const pageId = this.model.get('id');
    if (pageId) {
      this._viewPage(pageId);
      return;
    }

    Backbone.history.history.back();
  }

  _viewPage(pageId) {
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/article/${ pageId }`, {
      trigger: true
    });
  }

  _showErrors(errors) {
    const currentErrors = _.compact(errors);
    window.app.layout.flash.error(currentErrors);
  }

  onPageAccessError(xhr) {
    xhr.skipGlobalHandler = true;
    window.app.layout.flash.error(I18n.t('discover.pageTypes.article.error.3059'));

    Backbone.history.history.back();
  }

  onLockError(xhr) {
    const exception = AxonifyExceptionFactory.fromResponse(xhr);
    const errCode = exception.getErrorCode();

    // Users access rights have changed, flash error and go back to viewing only
    // Please note that server is currently throwing CLIENT_ERROR_NOT_AUTHORIZED when locked by another user.
    // This should be replaced to allow the below to work again in phase 2 community management
    if (errCode === AxonifyExceptionCode.CLIENT_ERROR_NOT_AUTHORIZED) {
      this.onPageAccessError(xhr);

    // page has been locked by someone else, so no need for oops screen
    // so skip global handler and go to page view
    } else if (errCode === AxonifyExceptionCode.CLIENT_ERROR_OBJECT_ALREADY_LOCKED
      || errCode === AxonifyExceptionCode.CLIENT_ERROR_NO_SUCH_ENTITY) {
      xhr.skipGlobalHandler = true;
      window.app.layout.flash.error(I18n.t(`discover.pageTypes.${ this.model.get('type') }.error.${ errCode }`));

      // -- SE-18312 --
      // Since we know the edit page is locked, if they're trying to edit, take them to the view.
      // and replace the history so they can't go back to /edit
      let fragment = Backbone.history.getFragment();
      if (fragment.includes('/edit')) {
        fragment = fragment.replace('/edit', '');
        Backbone.history.navigate(fragment, {
          trigger: true,
          replace: true
        });
      } else {
        Backbone.history.history.back();
      }
    }
  }

  onDestroy() {
    // remove the lock
    if (this.lockController) {
      this.lockController.destroy();
    }
  }
}


module.exports = ArticleEditController;
