const { Model } = require('Backbone');
const { LayoutView } = require('Marionette');
const I18n = require('@common/libs/I18n');
const Form = require('@common/components/forms/Form');
const CheckboxGroup = require('@common/components/checkboxGroup/CheckboxGroup');
const GroupList = require('@admin/apps/main/collections/GroupList');
const UIKit = require('@training/widgets/UIKit');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');

require('@common/components/forms/editors/checkboxes/Form.Editor.Checkboxes');
require('@common/libs/behaviors/scrollable/Scrollable');

class AccessSettingsGroupModalView extends LayoutView {
  initialize(options = {}) {
    ({
      groupList: this.groupList,
      selectedGroups: this.selectedGroups,
      businessUnitId: this.businessUnitId
    } = options);

    this.hasBusinessUnit = this.businessUnitId !== null
      && this.businessUnitId !== ''
      && this.businessUnitId !== '0'
      && String(this.businessUnitId) !== '-1';


    if (this.hasBusinessUnit) {
      this.groupList.fetch({
        data: {
          businessUnitId: this.businessUnitId,
          filterByBusinessUnitId: true
        },
        reset: true,
        success: () => {
          if (!this.isDestroyed) {
            this.render();
          }
        },
        error: (collection, response) => {
          console.error('AccessSettingsGroupModalView - Error fetching groups:', response);
        }
      });
    } else if (!this.hasBusinessUnit && !this.groupList.length) {
      this.groupList.fetch({
        reset: true,
        success: () => {
          if (!this.isDestroyed) {
            this.render();
          }
        },
        error: (collection, response) => {
          console.error('AccessSettingsGroupModalView - Error fetching groups (global):', response);
        }
      });
    }

    this.model = new Model({
      groups: this.selectedGroups.models
    });
  }

  behaviors() {
    return {
      Scrollable: {
        scrollableContainer: '.selectableList'
      }
    };
  }

  className() {
    return 'group-access-modal';
  }

  getTemplate() {
    return require('@training/apps/search/communitiesManagement/accessSettings/AccessSettingsGroupModalView.html');
  }

  ui() {
    return {
      form: '.js-form',
      groupList: '.js-group-list'
    };
  }

  events() {
    return {
      'click .js-close-button': 'destroy'
    };
  }

  onRender() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.add'),
        className: 'ax-button ax-button--branded',
        onClick: this.onClickAdd.bind(this)
      }
    ];

    if (this.actionBar) {
      this.actionBar.setActionBar({ buttons });
    }

    this._initializeFormAndCheckboxGroup();
  }

  _initializeFormAndCheckboxGroup() {
    let filteredGroups = this.groupList;

    // CRITICAL: For --Select One-- (-1), we want to show ALL groups
    // Do not filter them at all
    if (this.hasBusinessUnit) {
      const numericBusinessUnitId = Number(this.businessUnitId);

      const filteredModels = this.groupList.filter((group) => {
        const groupBuId = group.get('businessUnitId');
        return groupBuId === numericBusinessUnitId || groupBuId === null || groupBuId === '';
      });

      filteredGroups = new GroupList(filteredModels);
    } else {
      // No filtering applied to groups - show all
    }

    if (this.ui.groupList && this.ui.groupList.length) {
      this.groupsCheckboxGroup = new CheckboxGroup({
        el: this.ui.groupList,
        collection: filteredGroups,
        filterFunction(item, value) {
          return item.get('name').toLowerCase()
            .indexOf(value.toLowerCase()) >= 0;
        }
      });

      if (this.ui.form && this.ui.form.length) {
        this.form = new Form({
          el: this.ui.form,
          model: this.model,
          fields: ['groups'],
          data: {
            groups: []
          },
          context: {
            groupList: filteredGroups
          }
        });

        const selectedGroupsClone = this.selectedGroups.clone();
        selectedGroupsClone.remove(-1);
        if (selectedGroupsClone.length === filteredGroups.length) {
          this.groupsCheckboxGroup.checkAll();
        }

        this.listenTo(this.groupsCheckboxGroup, 'checkboxGroup:updated', this._onCheckboxGroupUpdated);
      }
    }
  }

  onClickAdd() {
    const everyoneGroup = this.selectedGroups.get(-1);
    const newSelections = this.model.get('groups');

    // SE-18129: Need to maintain any previously selected access levels that may get overridden by the generic group
    // Models backing the checkboxes that are absent of an accessLevel value
    newSelections.forEach((group) => {
      const match = this.selectedGroups.where(group);
      if (match.length > 0) {
        group.accessLevel = match[0].get('accessLevel');
      }
    });

    this.selectedGroups.reset(newSelections);
    this.selectedGroups.unshift(everyoneGroup);
    this.triggerMethod('add:groups', this.selectedGroups);
    this.destroy();
  }

  _onCheckboxGroupUpdated(allChecked, allUnchecked) {
    if (allChecked) {
      let filteredGroups = this.groupList.models;

      if (this.hasBusinessUnit) {
        filteredGroups = this.groupList.filter((group) => {
          const groupBuId = group.get('businessUnitId');
          return groupBuId === this.businessUnitId || groupBuId === null || groupBuId === '';
        });
      }

      this.model.set('groups', filteredGroups);
    } else if (allUnchecked) {
      this.model.set('groups', []);
    }
  }

  _onClickGroupButton() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal'
    });

    const groupListCopy = new GroupList(this.groupList.toJSON());
    const businessUnitId = this.model.get('businessUnitId');

    const modalChildView = new AccessSettingsGroupModalView({
      selectedGroups: this.selectedGroups,
      groupList: groupListCopy,
      businessUnitId: businessUnitId
    });

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    this.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });

    this.listenToOnce(modalChildView, 'add:groups', (selectedGroups) => {
      this.groupTable.collection.reset(selectedGroups.models);
      this.groupTable.render();
    });
  }
}

module.exports = AccessSettingsGroupModalView;
