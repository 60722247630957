const GroupDateConditionTypesEnum = require('@common/data/enums/GroupDateConditionTypesEnum');
const I18n = require('@common/libs/I18n');
const dateHelpers = require('@common/libs/dateHelpers');
const FilterableAttributesEnum = require('@common/data/enums/FilterableAttributesEnum');

const GroupDateConditionHelpers = {
  isDateConditionComplete: (dateCondition) => {
    const {
      dateType,
      dateConditionType,
      numOfDays,
      specificDate
    } = dateCondition || {};

    const isNumOfDaysDateConditionType = [
      GroupDateConditionTypesEnum.WithinNumOfDays,
      GroupDateConditionTypesEnum.BeyondNumOfDays
    ].includes(dateConditionType);

    const isSpecificDateDateConditionType = [
      GroupDateConditionTypesEnum.BeforeSpecificDate,
      GroupDateConditionTypesEnum.AfterSpecificDate
    ].includes(dateConditionType);

    const hasNumOfDays = numOfDays !== null && numOfDays !== undefined;
    const validNumOfDays = !isNumOfDaysDateConditionType || hasNumOfDays;
    const validSpecificDate = !isSpecificDateDateConditionType || specificDate;

    return Boolean(dateType && dateConditionType && (hasNumOfDays || specificDate) && validNumOfDays && validSpecificDate);
  },
  getDateConditionPreview: (dateCondition) => {
    if (!GroupDateConditionHelpers.isDateConditionComplete(dateCondition)) {
      return '';
    }

    const {
      dateType,
      dateConditionType,
      numOfDays,
      specificDate
    } = dateCondition || {};

    const dateTypeString = I18n.t(`userDateCondition.dateTypes.${ dateType }`);
    const dateTypeVerb = I18n.t(`userDateCondition.previewText.verbs.${ dateType }`);
    const formattedDate = specificDate ? dateHelpers.convertDateFormatShortDateWithStrings(specificDate) : '';
    const isNumOfDaysDateConditionType = [
      GroupDateConditionTypesEnum.WithinNumOfDays,
      GroupDateConditionTypesEnum.BeyondNumOfDays
    ].includes(dateConditionType);
    const previewStringKey = isNumOfDaysDateConditionType && numOfDays === 1
      ? dateConditionType + 'Singular' : dateConditionType;

    return I18n.t(`userDateCondition.previewText.${ previewStringKey }`, {
      dateTypeString,
      numOfDays,
      dateTypeVerb,
      specificDate: formattedDate
    });
  },
  getDateConditionConfigForQuickFilter: () => {
    return {
      [FilterableAttributesEnum.DateCondition]: {
        name: FilterableAttributesEnum.DateCondition,
        strKey: 'multiFilter.quickFilterSelector.dateCondition'
      }
    }
  }
};

module.exports = GroupDateConditionHelpers;
