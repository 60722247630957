const Backbone = require('Backbone');
const ExclusionRulesEnum = require('@common/data/enums/ExclusionRulesEnum');
const async = require('async');

class GroupCriteria extends Backbone.Model {
  preinitialize(...args) {
    this._applyTo = this._applyTo.bind(this);
    super.preinitialize(...args);
  }

  defaults() {
    return {
      groupAreasOfInterest: [],
      jobTitles: [],
      departments: [],
      locations: [],
      groupTeamAttributes: [],
      groupTeamAttributesHeader: [],
      linesOfBusiness: [],
      locationTypes: [],
      dateCondition: null,
      departmentsFilterRule: ExclusionRulesEnum.INCLUDE,
      linesOfBusinessFilterRule: ExclusionRulesEnum.INCLUDE,
      jobTitlesFilterRule: ExclusionRulesEnum.INCLUDE,
      locationsFilterRule: ExclusionRulesEnum.INCLUDE,
      locationTypesFilterRule: ExclusionRulesEnum.INCLUDE
    };
  }

  fetch(fetchKeys = null) {
    const promise = $.Deferred();

    const defaultAsyncObject = {
      groupAreasOfInterest: (done) => {
        return window.app.db.fetchGroupAreasOfInterest(() => {
          return this._applyTo('groupAreasOfInterest', window.app.db.groupAreasOfInterest, done);
        });
      },
      groupTeamAttributes: (done) => {
        return window.app.db.fetchGroupTeamAttributes(() => {
          return this._applyTo('groupTeamAttributes', window.app.db.groupTeamAttributes, done);
        });
      },
      jobTitles: (done) => {
        return window.app.db.fetchJobTitles(() => {
          return this._applyTo('jobTitles', window.app.db.jobTitleList, done);
        });
      },
      departments: (done) => {
        return window.app.db.fetchDepartments(() => {
          return this._applyTo('departments', window.app.db.departmentList, done);
        });
      },
      locations: (done) => {
        return window.app.db.fetchLocations(() => {
          return this._applyTo('locations', window.app.db.locationList, done);
        });
      },
      linesOfBusiness: (done) => {
        return window.app.db.fetchLinesOfBusiness(() => {
          return this._applyTo('linesOfBusiness', window.app.db.linesOfBusiness, done);
        });
      },
      locationTypes: (done) => {
        return window.app.db.fetchLocationTypes(() => {
          return this._applyTo('locationTypes', window.app.db.locationTypeList, done);
        });
      }
    };

    const asyncObject = fetchKeys ? fetchKeys.reduce((acc, key) => {
      if (defaultAsyncObject[key]) {
        acc[key] = defaultAsyncObject[key];
      }
      return acc;
    }, {}) : defaultAsyncObject;

    async.auto(asyncObject
      , () => {
        return promise.resolve();
      });

    return promise.promise();
  }

  _applyTo(attr, data, done) {
    // Keep original list
    this[attr] = data;
    this.set(attr, data.toJSON());
    if (attr === 'groupTeamAttributes') {
      this.groupTeamAttributesHeader = this.get(attr).map((attribute) => {
        return attribute.name
      });
    }
    done();
  }
}

module.exports = GroupCriteria;
