import TooltipButton from '@common/components/tooltipButton/TooltipButton';
import {
  AxStack,
  type IconNames
} from '@common/modules/react/themes/components/';
import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import {
  InputLabel as MuiInputLabel,
  styled,
  useTheme,
  type InputLabelProps as MuiInputLabelProps,
  type PopperProps,
  type SxProps,
  type Theme
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type ToOmit = 'color' |
  'disableAnimation' |
  'filled' |
  'shrink' |
  'size' |
  'variant';

type AxInputLabelProps = Omit<MuiInputLabelProps, ToOmit> & {
  /**
   * Props for the tooltip
   */
  tooltipProps?: {
    /**
     * Tooltip text to display
     */
    title?: string | React.ReactNode
    /**
     * Icon to display in the tooltip
     */
    iconName?: IconNames
    /**
     * Custom styles for the tooltip icon
     */
    sx?: SxProps<Theme>,
    /**
     * Custom styles for the tooltip popper
     */
    PopperProps?: Partial<PopperProps>
  }
  /**
   * The component to render the input label as
   */
  as?: React.ElementType
};

const styledOptions = {
  name: 'AxInputLabel'
};

const StyledAxInputLabel = styled(MuiInputLabel, styledOptions)<AxInputLabelProps>(({ theme }) => {
  return {
    color: 'var(--ax-color-grey-80)',
    lineHeight: theme.uiKit.lineHeightS,
    // TODO: Remove !important. This is a temporary fix for the font size issue caused by ax-font.less l:83
    fontSize: `${ theme.uiKit.fontSizeXS } !important`,
    // TODO: Remove !important. This is a temporary fix for the margin issue caused MUI-Label
    margin: `0 !important`,
    '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme),
    '&.Mui-error': {
      color: theme.uiKit.colorRed60
    },
    '&.Mui-disabled': {
      color: 'var(--ax-color-grey-80)'
    }
  };
});

/**
 * Wraps the MUI InputLabel component and adds a tooltip to the label.
 *
 * ### Links
 * - • [MUI | InputLabel API](https://mui.com/material-ui/api/input-label/)
 * - • [Mockups | Based on input label in Admin Communications](https://www.figma.com/design/GBT10L4YNfhM2ox0jJn5qu/Admin-Communications?node-id=4158-47098&t=77qcKMiJTH80aqHw-4)
 */
export const AxInputLabel = forwardRef(({
  tooltipProps,
  children,
  sx,
  ...otherProps
}: AxInputLabelProps, ref: Ref<HTMLLabelElement>) => {

  const theme = useTheme();

  const inputLabelProps: AxInputLabelProps = {
    ...otherProps
  };

  if (!tooltipProps) {
    return <StyledAxInputLabel
      { ...inputLabelProps }
      sx={ sx }
      ref={ ref } >
      { children }
    </StyledAxInputLabel>;
  }

  return (
    <AxStack
      direction='row'
      alignItems='center'
      marginBottom={ theme.uiKit.spacingXS }
      sx={ sx }
    >
      <StyledAxInputLabel { ...inputLabelProps } ref={ ref } >
        { children }
      </StyledAxInputLabel>
      <TooltipButton
        tooltipProps={{
          title: tooltipProps.title,
          PopperProps: tooltipProps.PopperProps
        }}
        buttonProps={{
          size: 'small',
          iconName: tooltipProps.iconName,
          sx: {
            fontSize: theme.uiKit.fontSizeS,
            ...tooltipProps.sx
          }
        }}
      />
    </AxStack>
  );
});

export default AxInputLabel;
