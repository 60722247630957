const Marionette = require('Marionette');
const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');
const I18n = require('@common/libs/I18n');
const PageableListViewController = require('@common/components/pageableList/PageableListViewController');
const SharedCommunityItemView = require('@training/apps/articles/SharedCommunityItemView');

class ArticleShareModal extends Marionette.LayoutView {
  initialize(options = {}) {
    ({
      shareableCommunitiesList: this.shareableCommunitiesList
    } = options);
  }

  getTemplate() {
    return require('@training/apps/articles/modals/ArticleShareModal.html');
  }

  templateHelpers() {
    return {
      shareMessage: this.shareableCommunitiesList?.length > 0 ? I18n.t('discover.share.meta') : I18n.t('discover.share.empty')
    };
  }

  className() {
    return 'page-wrapper parent-height';
  }

  regions() {
    return {
      sharedCommunitiesListRegion: '.js-shared-communities-list'
    };
  }

  onRender() {
    const buttons = [
      {
        type: 'customText',
        text: I18n.t('general.close'),
        className: 'white',
        onClick: this.destroy.bind(this)
      }
    ];

    this.actionBar.setActionBar({ buttons });
  }

  onAttach() {
    this._showSharedCommunitiesList();
  }

  _showSharedCommunitiesList() {
    const viewControllerFactory = new ViewControllerFactory();
    const options = {
      collection: this.shareableCommunitiesList,
      itemViewClass: SharedCommunityItemView,
      itemViewOptions: {

      }
    };

    const pageableController = viewControllerFactory.create(PageableListViewController, options);
    pageableController.show(this.sharedCommunitiesListRegion);
  }
}

module.exports = ArticleShareModal;
