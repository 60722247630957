const Backbone = require('Backbone');
const Group = require('../models/Group');
const GroupType = require('@common/data/enums/GroupType');

class GroupList extends Backbone.Collection {
  preinitialize(...args) {
    this.sync = this.sync.bind(this);
    super.preinitialize(...args);
  }

  get model() {
    return Group;
  }

  apiEndpoint() {
    return GroupType.getGroupEndpointByType(GroupType.TrainingGroup);
  }

  requestUrl() {
    return `${ window.apps.auth.getAuthUrl() }${ GroupType.getGroupEndpointByType(GroupType.TrainingGroup) }`;
  }

  comparator(model) {
    return model.get('name');
  }

  parse(res) {
    return res ? res.entities : res;
  }

  filterByType(type) {
    if ((type == null)) {
      return this;
    }

    return new GroupList(this.filter((group) => {
      return group.get('type') === type;
    }));
  }

  sync(method, model, options = {}) {
    options.url = `${ window.apps.auth.getAuthUrl() }`;

    const methodName = method.toLowerCase();

    switch (methodName) {
      case 'create':
        options.url += GroupType.getGroupEndpointByType(this.type);
        break;
      case 'read': {
        const type = this.type != null ? this.type : 0;
        if (options.userId != null) {
          options.url += `/users/${ options.userId }/groups`;
        } else {
          options.url += GroupType.getGroupEndpointByType(type);
        }
        if (options.filterCustomAccessOnly != null) {
          options.data = $.extend(true, options.data, { filterCustomAccessOnly: options.filterCustomAccessOnly });
        }
        if (options.businessUnitId != null) {
          options.data = $.extend(true, options.data || {}, { businessUnitId: options.businessUnitId });
        }
        break;
      }
      default:
        delete options.url;
    }

    return Backbone.sync(method, model, options);
  }
}

module.exports = GroupList;
