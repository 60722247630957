import {
  FormHelperText as MuiFormHelperText,
  styled,
  useTheme,
  type FormHelperTextTypeMap as MuiFormHelperTextTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFormHelperTextCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

type PropsToOmit = 'margin' | 'variant';

export type AxFormHelperTextTypeMap<P = object, D extends React.ElementType = 'div'> = {
  props: P & AxFormHelperTextCustomProps & Omit<MuiFormHelperTextTypeMap<P, D>['props'], PropsToOmit>;
  defaultComponent: D;
};

export type AxFormHelperTextProps<
  D extends ElementType = AxFormHelperTextTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxFormHelperTextTypeMap<P & AxFormHelperTextCustomProps, D>, D>;

const styledOptions = {
  name: 'AxFormHelperText'
};

const StyledAxFormHelperText = styled(MuiFormHelperText, styledOptions)<AxFormHelperTextProps>(() => {
  return {
    ...useAxFormHelperTextStyles()
  };
});

/**
 * AxFormHelperText helps to improve form accessibility and clarity by showing messages related to the input field, such as errors or hints.
 *
 * Use as the `helperText` within a form control like `AxTextField`
 * or as a child of `AxFormControlGroup`.
 * In this way, helper text is associated with a specific input field and the parent controls its state (e.g., error, disabled).
 *
 *
 * @example
 * // Usage within a TextField component (helperText prop)
 * <AxTextField
 *   label="Username"
 *   helperText="Your username must be unique."
 * />
 *
 * @example
 * // Usage as a child of FormControl
 * <AxFormControlGroup>
 *   {...}
 *   <AxFormHelperText>We'll never share your email.</AxFormHelperText>
 * </AxFormControlGroup>
 *
 */
export const AxFormHelperText: MuiOverridableComponent<AxFormHelperTextTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxFormHelperTextProps, ref: Ref<HTMLDivElement>) => {
  const FormHelperTextProps: AxFormHelperTextProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxFormHelperText { ...FormHelperTextProps } ref={ ref }>
      { children }
    </StyledAxFormHelperText>
  );
});

export default AxFormHelperText;

export const useAxFormHelperTextStyles = () => {
  const theme = useTheme();
  return {
    fontSize: theme.uiKit.fontSizeXS,
    color: 'var(--ax-color-grey-60)',
    fontWeight: theme.uiKit.fontWeightRegular,
    lineHeight: theme.uiKit.lineHeightXS,
    margin: 0,
    '&.Mui-disabled': {
      color: 'var(--ax-color-grey-60)'
    }
  };
};
