import {
  AvatarGroup as MuiAvatarGroup,
  styled,
  type AvatarGroupProps as MuiAvatarGroupProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';
import { useGetSizeStyles } from './hooks';
import { type AX_AVATAR_SIZE } from './types';

type AxAvatarGroupProps = Omit<MuiAvatarGroupProps, 'spacing'> & {
  /**
   * The size for all avatars in the group.
   * @default 'm'
   */
  size?: AX_AVATAR_SIZE;
};

const styledOptions = {
  name: 'AxAvatarGroup'
};

const StyledAxAvatarGroup = styled(MuiAvatarGroup, styledOptions)<AxAvatarGroupProps>(({
  size,
  theme
}) => {

  // TODO: this came from figma but can't find anymore. Check if it's still needed. Otherwise, use the AxAvatar fontSize.
  const fontSize = {
    xs: '1rem',
    sm: theme.uiKit.fontSizeXS,
    md: theme.uiKit.fontSizeM,
    lg: theme.uiKit.fontSizeL,
    xl: theme.uiKit.fontSizeXL
  };

  return {
    '.MuiAvatar-root': {
      ...useGetSizeStyles(size || 'md'),
      fontSize: fontSize[size || 'md']
    }
  };
});

/**
 * Wrapper for MUI AvatarGroup component.
 *
 * ### Links
 * - [MUI Demo](https://mui.com/material-ui/react-avatar/#grouped)
 * - [MUI API](https://mui.com/material-ui/api/avatar-group/)
 */
export const AxAvatarGroup = forwardRef(({
  children,
  slotProps,
  ...otherProps
}: AxAvatarGroupProps, ref: Ref<HTMLDivElement>) => {
  const AvatarGroupProps: AxAvatarGroupProps = {
    children,
    slotProps: {
      additionalAvatar: {
        // Custom styles for the surplus avatar
        sx: {
          backgroundColor: 'var(--ax-avatar-background-color-default)',
          color: 'primary.contrastText'
        },
        ...slotProps?.additionalAvatar
      },
      ...slotProps
    },
    ...otherProps
  };

  return (
    <StyledAxAvatarGroup { ...AvatarGroupProps } ref={ ref }>
      { children }
    </StyledAxAvatarGroup>
  );
});

export default AxAvatarGroup;
