// Module
var code = `<td class="table-layout__col1">
  <% if (isEveryoneGroup) { %>
    <span><%- everyoneLabel %></span>
  <% } else { %>
    <span class="group-name"><%- name %></span>
  <% } %>
</td>
<td colspan="4" class="table-layout__col2">
  <form class="js-form">
    <ul
      class="ax-grid"
      data-shallow="true"
      data-field="accessLevel"
      data-editor="CascadingSelectCheckboxes"
      data-options="accessLevelsList">
    </ul>
  </form>
</td>
<td class="table-layout__col3">
  <% if (!isEveryoneGroup) { %>
    <button type="button" class="icon-trash js-delete-btn" aria-label="<%- removeGroupLabel %>" title="<%- removeGroupLabel %>"></button>
  <% } %>
</td>
`;
// Exports
module.exports = code;