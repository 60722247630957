const Backbone = require('Backbone');
const { LayoutView } = require('Marionette');
const $os = require('detectOS');
const UIKit = require('@training/widgets/UIKit');
const I18n = require('@common/libs/I18n');
const SearchUrlHelper = require('@training/apps/search/SearchUrlHelper');
const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');

class CommunityHeaderView extends LayoutView {
  initialize(options = {}) {
    ({
      searchPageState: this.searchPageState,
      community: this.community
    } = options);
  }

  className() {
    return 'community-header ax-grid ax-grid--align-items-center';
  }

  getTemplate() {
    return require('@training/apps/search/CommunityHeaderView.html');
  }

  templateHelpers () {
    return {
      shouldShowCommunityManagementButton: this._shouldShowCommunityManagementButton(),
      communityName: this.community && this.community.getName(),
      communityDescription: this.community && this.community.getDescription()
    };
  }

  regions() {
    return {
      breadcrumb: '.js-community-breadcrumb',
      communityIcon: '.js-community-icon',
      createButtonRegion: '.js-create-button-region'
    };
  }

  events() {
    return {
      'click .js-community-management-button': this._onContextMenuButtonClick.bind(this),
      'click .js-see-more-button': this._onShowFullDescriptionButtonClick.bind(this)
    }
  }

  ui() {
    return {
      communityDescription: '.js-community-description',
      showFullDescriptionButton: '.js-see-more-button'
    }
  }

  onAttach() {
    const $desc = this.ui.communityDescription;
    if ($desc.length && $desc[0].scrollHeight <= $desc[0].offsetHeight) {
      this.ui.showFullDescriptionButton.hide();
    }
  }

  _onContextMenuButtonClick() {
    const community = this.searchPageState.get('community');
    Backbone.history.navigate(`${ SearchUrlHelper.BASE_SEARCH_HASH }/articles/community-${ community.id }/communityManagement`, true);
  }

  _onShowFullDescriptionButtonClick() {
    const modalView = new AccessibleModalView({
      id: 'modalview',
      className: 'modal confirm-dialog-view modal--s community-description-modal'
    });

    const modalChildView = new ConfirmDialogView({
      title: this.community.getName(),
      confirmationText: this.community.getDescription()
    });

    modalChildView.setButtons([{
      type: 'customText',
      text: I18n.t('general.done'),
      className: 'blue',
      onClick: () => {
        modalChildView.destroy();
      }
    }]);

    window.app.layout.presentModal(modalView, { closeClick: false });
    modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });

    modalView.listenToOnce(modalChildView, 'destroy', () => {
      window.app.layout.dismissModal();
    });
  }

  /**
   * Returns true if the community management button should be shown.
   * Employs the "return early, return often" pattern. If any of the conditions are not met, the user
   * should not see the community management button.
   * @returns {boolean}
   */
  _shouldShowCommunityManagementButton() {
    const community = this.searchPageState.get('community');
    if (!community) {
      return false;
    }

    // we only support community management on desktop, not mobile
    const isDesktop = $os.desktop;
    if (!isDesktop) {
      return false;
    }

    // hasCommunityManagementAccess returns true when the user is an Admin or BUAdmin, and has flex admin
    // access to the discover area. In other words, it only checks if the user has permission to administer
    // communities in general, it doesn't check if the user has access to the specific community.
    const hasCommunityManagementAccess = window.apps.auth.session.user.hasCommunityManagementAccess();
    if (!hasCommunityManagementAccess) {
      return false;
    }

    // the permission to manage specifically this community is returned by the API in the permittedActions
    // property of the community object.
    const isAllowedToManageThisCommunity = community.get('permittedActions')['EDIT_COMMUNITY_SETTINGS'];
    if (!isAllowedToManageThisCommunity) {
      return false;
    }

    return true;
  }
}

module.exports = CommunityHeaderView;
