import type {
  FileType,
  UploadProgressStatus
} from '@common/interfaces/SharedTypes';
import I18n from '@common/libs/I18n';
import { getFileType } from '@common/libs/helpers/app/FileHelpers';
import {
  AxBox,
  AxCircularProgress,
  AxIcon,
  AxIconButton,
  AxStack,
  AxTooltip,
  AxTypography
} from '@common/modules/react/themes/components';
import {
  styled,
  useTheme
} from '@mui/material';
import { type CSSObject } from '@mui/system';
import type {
  MouseEventHandler,
  PropsWithChildren,
  Ref
} from 'react';
import {
  forwardRef,
  useEffect,
  useState
} from 'react';

export interface FileUploadThumbnailProps extends PropsWithChildren {
  /**
  *  This shows the upload & processing status of the image
  *  @default 'waiting'
  **/
  status: UploadProgressStatus;
  /**
  *  image bits
  **/
  fileName: string | null;
  /**
  *  Presumably if you click on it you'll see a full sized preview
  **/
  onClick?: MouseEventHandler;
  onClickRemoveButton?: MouseEventHandler;
}

const styledOptions = {
  name: 'FileUploadThumbnail',
  onclick,
  shouldForwardProp: (prop: string) => {
    // This callback determines whether a prop becomes an HTML attribute.
    return prop !== 'status' && prop !== 'fileName';
  }
};

const StyledFileUploadThumbnail = styled(AxBox, styledOptions)<FileUploadThumbnailProps>(({
  theme
}) => {
  const defaultStyles: CSSObject = {
    fontSize: theme.uiKit.fontSizeXS,
    borderColor: theme.uiKit.colorGrey40,
    backgroundColor: theme.uiKit.colorGrey20,
    width: 'fit-content',
    borderStyle: 'solid',
    borderRadius: theme.uiKit.spacingS,
    marginRight: theme.uiKit.spacingXS,
    position: 'relative',
    paddingRight: '2rem'
  };

  return defaultStyles;
});

export const FileUploadThumbnail = forwardRef(({
  status = 'waiting',
  fileName,
  onClick,
  onClickRemoveButton,
  ...otherProps
}: FileUploadThumbnailProps, ref: Ref<HTMLDivElement>): JSX.Element => {
  const props = {
    status,
    fileName,
    onClick,
    onClickRemoveButton,
    ...otherProps
  };

  const [iconClassName, setIconClassName] = useState<string | null>(null);

  const getIconForType = (fileType:FileType|null) => {
    if (fileType) {
      // TODO: Add support for html, text, file, slide, zip
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (fileType) {
        case 'image':
          return 'icon-image';
        case 'video':
          return 'icon-film';
        case 'doc':
          return 'icon-file_text';
        case 'spreadsheet':
          return 'icon-spreadsheet';
        case 'pdf':
          return 'icon-pdf';
        case 'audio':
          return 'icon-audio';
        default:
          return 'icon-file_alt';
      }
    }
    return null;
  };

  useEffect(() => {
    if (fileName === null) {
      setIconClassName('icon-question');
    } else {
      const fileType = getFileType(fileName) as FileType;
      setIconClassName(getIconForType(fileType));
    }
  }, [fileName]);

  const theme = useTheme();

  return (
    <StyledFileUploadThumbnail { ...props } ref={ ref }>
      <AxStack
        onClick={ onClick }
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <AxBox
          className='icon-container'
          sx={{
            height: theme.uiKit.spacingXL,
            width: theme.uiKit.spacingXL,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {
            (status && status !== 'done')
              ? <AxCircularProgress
                size={ 15 }
                aria-label={ I18n.t('general.upload.inProgress') }
              ></AxCircularProgress>
              : <AxIcon
                className={ iconClassName || '' }
              />
          }
        </AxBox>

        <AxTypography variant='caption'>
          { fileName }
        </AxTypography>

      </AxStack>

      <AxTooltip
        title={ I18n.t('general.remove') }
        placement='top'
        arrow
      >
        <AxIconButton
          onClick={ onClickRemoveButton }
          size='small'
          variant='floating'
          sx={{
            position: 'absolute',
            top: '-1rem',
            right: '-1rem'
          }}
        ><AxIcon className='icon-remove'/></AxIconButton>
      </AxTooltip>
    </StyledFileUploadThumbnail>
  );
});

export default FileUploadThumbnail;
