module.exports = {
  BUSINESS_UNIT_ID_FIELD: 'businessUnitId',

  getBusinessUnitId() {
    return this.get(this.BUSINESS_UNIT_ID_FIELD);
  },

  setBusinessUnitId(value) {
    return this.set(this.BUSINESS_UNIT_ID_FIELD, value);
  },

  hasBusinessUnitId() {
    return (this.get(this.BUSINESS_UNIT_ID_FIELD) != null);
  }
};
