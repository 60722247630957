const _ = require('underscore');
const I18n = require('@common/libs/I18n');
const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const BusinessUnit = require('@common/data/models/businessUnit/BusinessUnit');
const BusinessUnitList = require('@common/data/collections/businessUnit/BusinessUnitList');

// This class is responsible for fetching and providing business units
// to the Datastore, without it needing to know the specifics of how
// fetching these units work
class BusinessUnitRepository {
  constructor(executingUser, isTeamAdminEnabled) {
    this.executingUser = executingUser;
    this.isTeamAdminEnabled = isTeamAdminEnabled;
  }

  getAll(options, callback) {
    const getOptions = options || { fetch: true };
    const businessUnitList = new BusinessUnitList();

    if (this.isTeamAdminEnabled) {
      // We should inject the Global Business Unit now
      this._injectSystemBusinessUnits(businessUnitList);

      if (getOptions.fetch === true) {
        businessUnitList.fetch({
          success: () => {
            this._injectSystemBusinessUnits(businessUnitList);
            if (_.isFunction(callback)) {
              callback(businessUnitList);
            }
          },
          data: getOptions
        });
      }
    } else {
      // We do this to simplify client calling code to not have to concern
      // themselves about the business unit flag.
      _.defer(() => {
        if (_.isFunction(callback)) {
          callback(businessUnitList);
        }
      });
    }

    return businessUnitList;
  }

  _injectSystemBusinessUnits(tenantBusinessUnits) {
    if (this.executingUser.isAdminUser() || this.executingUser.isContentUser()) {
      tenantBusinessUnits.add(this._getSystemBusinessUnits());
    }
  }

  _getSystemBusinessUnits() {
    const str = I18n.t('settings.businessUnits.global');
    return new BusinessUnit({
      name: LocalizableString.fromString(str, 'EN'),
      id: null
    });
  }
}

module.exports = BusinessUnitRepository;
