const Enum = require('@common/data/enums/Enum');

/**
 * Enum representing business unit types
 * GLOBAL: Represents global business unit (values: 0, "", "0", "1", null, undefined)
 * UNSELECTED: Represents unselected business unit (value: "-1")
 */
module.exports = Enum({
  GLOBAL: '0',
  UNSELECTED: '-1'
}, {
  isGlobal: function(buId) {
    const id = String(buId || '');
    return id === '' || id === '0' || id === '1' || buId === null || buId === undefined;
  },
  isUnselected: function(buId) {
    return String(buId) === this.UNSELECTED;
  }
});
