import {
  ImageListItem as MuiImageListItem,
  styled,
  type ImageListItemTypeMap as MuiImageListItemTypeMap
} from '@mui/material';
import {
  type OverridableComponent as MuiOverridableComponent,
  type OverrideProps as MuiOverrideProps
} from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxImageListItemCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
};

export type AxImageListItemTypeMap<P = object, D extends React.ElementType = 'li'> = MuiImageListItemTypeMap<P & AxImageListItemCustomProps, D>;

export type AxImageListItemProps<
  D extends ElementType = AxImageListItemTypeMap['defaultComponent'],
  P = object,
> = MuiOverrideProps<AxImageListItemTypeMap<P & AxImageListItemCustomProps, D>, D>;

const styledOptions = {
  name: 'AxImageListItem'
};

const StyledAxImageListItem = styled(MuiImageListItem, styledOptions)<AxImageListItemProps>(() => {
  return {};
});

export const AxImageListItem: MuiOverridableComponent<AxImageListItemTypeMap> = forwardRef(({
  children,
  ...otherProps
}: AxImageListItemProps, ref: Ref<HTMLLIElement>) => {
  const ImageListItemProps: AxImageListItemProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxImageListItem
      { ...ImageListItemProps }
      ref={ ref }
    >
      { children }
    </StyledAxImageListItem>
  );
});

export default AxImageListItem;
