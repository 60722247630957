const $os = require('detectOS');
const _ = require('underscore');
const TeamHierarchySelector = require('@common/components/teamHierarchySelector/TeamHierarchySelector');

// This component is extended to avoid the channel/vent used in the parent-most FilterableSelector
// since using channels is a bad practice in training part of our app
class TeamSelector extends TeamHierarchySelector {
  initialize(options) {
    super.initialize(options);
    this.initialTeamId = options.teamId;
    this.setInitialTeamId = this.setInitialTeamId.bind(this);
    this.getTeamById = this.getTeamById.bind(this);

    this.listenTo(this.collection, 'sync', this.setInitialTeamId);
  }

  events() {
    const newEvents = _.extend(super.events(), {
      'keyup .input-container': 'onShowDropdown'
    });
    return newEvents;
  }

  setInitialTeamId() {
    if (this.initialTeamId) {
      const initialTeam = this.getTeamById(this.initialTeamId);
      if (initialTeam) {
        this.setSelectedItem(initialTeam);
      }
    }
  }

  getTeamById(id) {
    if (this._treeEditor != null) {
      const teamNode = this.getNodeById(id);
      if (teamNode && teamNode.data) {
        return teamNode.data.model;
      }
    }
    return null;
  }

  getNodeById(id) {
    if (this._treeEditor) {
      return this._treeEditor.findNode((fancyTreeNode) => {
        return fancyTreeNode.data && fancyTreeNode.data.model
               && fancyTreeNode.data.model.id === id;
      });
    }
    return null;
  }

  onAttach() {
    this.setInitialTeamId();
  }

  openMenu() {
    this._opened = true;

    this.ui.contentFiller.css({
      height: this.ui.inputContainer.outerHeight()
    });

    this.$el.addClass('opened');

    this._toggleClearInput((this.filterText && this.filterText.length) > 0);
    this.selectInput();

    this.adjustDropdownPosition();

    if ($os.mobile) {
      this.triggerMethod('freeze:scrollable');
    }
  }

  closeMenu() {
    this._opened = false;
    this.$el.removeClass('opened');
    if (!this.hasAdvancedFilterApplied) {
      this.updateClearInput(this.getSelectedItem());
    }
    if (this.ui.textInput) {
      this.blurInput();
    }
    this.triggerMethod('unfreeze:scrollable');
  }
}

module.exports = TeamSelector;
