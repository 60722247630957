const { Model } = require('Backbone');

const LocalizableString = require('@common/data/models/translationStrings/LocalizableString');
const TranslatableStringValidators = require('@common/components/forms/validators/TranslatableStringValidators');

class BusinessUnit extends Model {

  apiEndpoint() {
    return 'businessUnits';
  }

  get className() {
    return 'BusinessUnit';
  }

  validators() {
    return {
      name: [TranslatableStringValidators.maxLength(120), TranslatableStringValidators.required]
    };
  }

  defaults() {
    return {
      name: new LocalizableString(),
      locationIds: []
    };
  }

  getassociatedTeamsIds() {
    return $.Deferred().resolve(this.get('locationIds'));
  }

  getName() {
    return this.get('name').getValueForLanguage('EN');
  }

  getNumberOfTeams() {
    return this.get('locationIds').length;
  }

  parse(resp) {
    resp.name = new LocalizableString(resp.name, {parse: true});
    return resp;
  }

  toOption() {
    return {
      id: this.id,
      value: this.getName()
    };
  }
}

module.exports = BusinessUnit;
