const _ = require('underscore');
const ChildCollectionTreeSourceMapper = require('@common/components/axonFancyTree/ChildCollectionTreeSourceMapper');
const FilterableSelector = require('@common/components/filterable_selector/FilterableSelector');
const HierarchySelectorToEditorAdapterSerializer = require('@common/components/hierarchy_selector/HierarchySelectorToEditorAdapterSerializer');
const HierarchyTreeEditor = require('@common/components/hierarchy_selector/Form.Editor.HierarchyTreeEditor');

require('@common/libs/behaviors/resizable/Resizable');
require('@common/libs/behaviors/scrollable/Scrollable');

require('@common/components/hierarchy_selector/hierarchy-selector.less');

class HierarchySelector extends FilterableSelector {
  constructor(options = {}) {
    super(options);
    this.filterChildren = this.filterChildren.bind(this);
    this._isMultiSelect = options.isMultiSelectEnabled != null ? options.isMultiSelectEnabled : false;

    const defaultTreeMode = this._isMultiSelect ? 2 : 1;
    this._selectMode = options.selectMode != null ? options.selectMode : defaultTreeMode;
    this._minExpandLevel = options.minExpandLevel != null ? options.minExpandLevel : 1;
    this._shouldRollupChildren = options.shouldRollupChildren != null ? options.shouldRollupChildren : false;

    this._sourceMapper = options.mapper != null ? options.mapper : new ChildCollectionTreeSourceMapper({showCheckbox: this._isMultiSelect});
    this._viewportRowCount = options.viewportRowCount;

    this._serializer = new HierarchySelectorToEditorAdapterSerializer({
      collection: this._collection,
      shouldRollupChildren: this._shouldRollupChildren
    });

    this._editorClass = options.editorClass || HierarchyTreeEditor;
    this._editorOptions = options.editorOptions;
  }

  getTemplate() {
    return `\
      <div class="filterable-selector-label"><%- labelText %></div>

      <div class="filterable-selector hierarchy-selector">

        <div class="input-container">
          <div class="title-wrapper">
            <a class="css-close-filter js-close-hierarchy-filter" href="#"></a>
            <div><%- titleText %></div>
          </div>

          <div class="input-wrapper clearfix">
          <%= axSearch({
            classNameInput: 'hierarchy-filter-current-text filter-current-text qa-input-filter',
            fullWidth: true,
            iconClassLeft: 'icon-filter'
          }) %>
          </div>
        </div>

        <div class="dropdown-container">
          <div class="hierarchy-collection-view dropdown dropdown-listing absolute-fit-parent">
            <div class="parent-height" data-editor="HierachyTreeEditor" data-field="selection" data-options="treeOptions"></div>
          </div>
        </div>

      </div>

      <div class="filter-content-filler"></div>\
    `;
  }

  events() {
    const newEvents = _.extend(super.events(), {
      'click .js-close-hierarchy-filter': 'onCloseFilter',
      'click .js-clear': 'onClearInput',
      'touchstart .js-clear': 'onClearInput'
    });
    return newEvents;
  }

  ui() {
    return _.extend(super.ui(), {
      childViewContainer: '.hierarchy-collection-view',
      textInput: '.hierarchy-filter-current-text'
    });
  }

  behaviors() {
    return {
      Resizable: {}
    };
  }

  childViewContainer() {
    return '.hierarchy-collection-view';
  }

  configureCollection() {
    // do nothing
  }

  _initialEvents() {
    // Don't listen to 'add', 'remove' and 'reset' events of the collection
  }

  onRenderTemplate() {
    super.onRenderTemplate();
    return this.initializeTree();
  }

  showCollection() {
    if (this._treeEditor != null) {
      this._treeEditor.render();
    }
    return this.syncSelectionToTree();
  }

  onCollectionResetUpdate() {
    if ((this.filterText == null)) {
      this.resetSelectedItem();
      this._renderChildren();
    }
  }

  onUpArrow() {
    // Up movements aren't needed since fancytree does it's own
    // movement stuff.
  }

  onDownArrow() {
    // Down movements from the input focus the tree's first item
    // so that the tree can then take over arrow key movements.
  }
  // TODO: Bake this into the editor onto a lower level; we can't do this here anymore
  // @_tree.getNodeByKey(@_collection.first().id)?.setFocus()

  initializeTree() {
    this._treeEditor = new this._editorClass({
      el: this.ui.childViewContainer,
      options: this._getEditorOptions()
    });

    this.listenTo(this._treeEditor, 'node:selected', this.onNodeSelected);

    return this.syncSelectionToTree();
  }

  syncSelectionToTree() {
    const selected = this.getSelectedItem();
    return (this._treeEditor != null ? this._treeEditor.setValue(selected) : undefined);
  }

  onNodeSelected() {
    if (!this._isMultiSelect) {
      this.closeMenu();
      this._commitTree();
    }
  }

  _commitTree() {
    const selectedItems = this._treeEditor.getValue();
    this.unselectItem();
    this.selectItem(selectedItems);
  }

  setSelectedItemInput(itemArg) {
    const item = itemArg || this.getSelectedItem();

    if (_.isArray(item) && (item.length > 0)) {
      const items = _.map(item, (selectedItem) => {
        return selectedItem?.name ?? selectedItem?.toOption().value;
      });

      this.setInputText(items.join(', '));
    } else {
      super.setSelectedItemInput(item);
    }
  }

  filterChildren(filterText = '') {
    this._treeEditor.search(filterText);
  }

  onDestroy() {
    this._treeEditor.destroy();
  }

  _getEditorOptions() {
    const defaultEditorOptions = {
      shouldRollupChildren: this._shouldRollupChildren,
      collection: this._collection,
      selectMode: this._selectMode,
      minExpandLevel: this._minExpandLevel,
      mapper: this._sourceMapper,
      serializer: this._serializer,
      isMultiSelectEnabled: this._isMultiSelect,
      viewportRowCount: this._viewportRowCount
    };

    return Object.assign(defaultEditorOptions, this._editorOptions);
  }

  onShowDropdown() {
    const showDropdownResult = super.onShowDropdown();
    this._treeEditor.adjustTreeRowCount();

    return showDropdownResult;
  }

  onResize() {
    this._treeEditor.adjustTreeRowCount();
  }
}

module.exports = HierarchySelector;
