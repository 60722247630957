const Backbone = require('Backbone');
const I18n = require('@common/libs/I18n');

class Community extends Backbone.Model {
  get apiEndpoint() {
    return '/pageCommunities';
  }

  get className() {
    return 'Community';
  }

  initialize() {
    this._isDirty = false;
    this.listenTo(this, 'change', () => {
      if (this.hasChanged()) {
        this._isDirty = true;
      }
    });
  }

  parse(res) {
    return (res && res.entity) || res;
  }

  isDirty() {
    return this._isDirty;
  }

  sync(...args) {
    const result = super.sync(...args);
    result.done(() => {
      this._isDirty = false;
    });
    return result;
  }

  toOption(formattingOptions = {}) {
    const {localeCode} = formattingOptions;
    return {
      id: this.id,
      value: this.id,
      label: this.getName(localeCode)
    };
  }

  // Maps the lang code over for support
  getName(langCode = I18n.getLocale()) {
    const { translations } = this.get('names');

    if (translations[langCode] != null) {
      return translations[langCode];
    }
    // Fall back to English when nothing else is available
    return this.get('names').default;
  }

  getDescription(langCode = I18n.getLocale()) {
    if (this.get('descriptions') != null) {
      const { translations } = this.get('descriptions');
      if (translations[langCode] != null) {
        return translations[langCode];
      }
    }

    return '';
  }


  arePostsEnabled() {
    return this.get('postsEnabled');
  }

  // we are going to cheat here and include Questions as being a type of Article
  // note here that "link" articles are not optional, they are always enabled
  areArticlesEnabled() {
    return this.get('articlesEnabled') || this.get('questionsEnabled');
  }

  hasNonPosts() {
    return this.getNonPostCount() > 0;
  }

  // this means: articles, questions, and links.
  getNonPostCount() {
    return this.get('nonPostCount') || 0;
  }

  getPostCount() {
    return this.get('postCount') || 0;
  }

  canPublish() {
    return this.get('permittedActions') && this.get('permittedActions').PUBLISH_ACCESS;
  }

  canMove() {
    return this.get('permittedActions') && this.get('permittedActions').MOVE_ACCESS;
  }

  canDelete() {
    return this.get('permittedActions') && this.get('permittedActions').DELETE_COMMUNITY;
  }

  canAuthor(PageType = null) {
    if (PageType) {
      const authorablePageTypes = this.get('authorablePageTypes');
      return authorablePageTypes && authorablePageTypes[PageType.toUpperCase()];
    }
    return this.get('permittedActions') && this.get('permittedActions').AUTHOR_ACCESS;
  }

  canEdit(PageType = null) {
    if (PageType) {
      const editablePageTypes = this.get('editablePageTypes');
      return editablePageTypes && editablePageTypes[PageType.toUpperCase()];
    }
    return false;
  }

  getBusinessUnitId() {
    return this.get('business_unit_id');
  }

  canEditForArticle(pageType, articleBusinessUnitId) {
    // First check if the page type is editable
    const canEditPageType = this.canEdit(pageType);
    const communityBusinessUnitId = this.getBusinessUnitId();

    if (!canEditPageType) {
      return false;
    }

    if (articleBusinessUnitId == null) {
      return true;
    }

    return communityBusinessUnitId === articleBusinessUnitId;
  }
}

module.exports = Community;
