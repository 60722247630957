module.exports = {
  ACCESS_KEY: 'access',

  // This flag is our old access mechanism and only exists on select legacy entities
  IS_EDITABLE_FLAG: 'isEditable',

  isEditable() {
    // This is for the content access stuff
    const hasEditAccessInAcl = this.get(this.ACCESS_KEY).includes('EDIT');
    const hasEditAccessFlag = this.get(this.IS_EDITABLE_FLAG) === true;

    return hasEditAccessInAcl || hasEditAccessFlag;
  },

  isViewable() {
    return this.get(this.ACCESS_KEY).includes('VIEW');
  },

  canAddChildren() {
    return this.get(this.ACCESS_KEY).includes('ADD_CHILD');
  },

  isDeletable() {
    return this.get(this.ACCESS_KEY).includes('DELETE');
  },

  isArchivable() {
    return this.get(this.ACCESS_KEY).includes('ARCHIVE');
  },

  isStatusEditable() {
    return this.get(this.ACCESS_KEY).includes('STATUS');
  }
};
